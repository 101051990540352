import { createRouter, createWebHashHistory } from 'vue-router'
import routes from './routes'

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

//路由守卫
router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem('token')
  // 目标路由不是登录页，并且还需要token验证，还没有token，那就直接给返回到登录页
  if (!token) {
    if (to.name == 'login' || to.name == 'register') {
      next()
    } else {
      next({ path: '/login' })
    }
  } else {
    // 放行
    next()
  }
})

export default router
