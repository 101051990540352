const routes = [
  {
    name: '/',
    path: '/',
    component: () => import('@/components/menu/Image.vue'),
  },
  {
    name: '/image',
    path: '/image',
    component: () => import('@/components/menu/Image.vue'),
  },
  {
    name: '/ImageClient',
    path: '/imageclient',
    component: () => import('@/components/menu/ImageClient.vue'),
  },
  {
    name: '/ImageDetail',
    path: '/imagedetail',
    component: () => import('@/components/menu/ImageDetail.vue'),
  },
  {
    name: 'chat',
    path: '/chat',
    component: () => import('@/components/menu/Chat.vue'),
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('@/components/Login.vue'),
  },
  {
    name: 'register',
    path: '/register',
    component: () => import('@/components/Register.vue'),
  },
  {
    name: 'account',
    path: '/account',
    component: () => import('@/components/menu/Account.vue'),
  },
]

export default routes
